import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgCruiseCover from "../../assets/images/journey/cruise/cruise-cover.png"

const CruisePage = () => (
  <Layout>
    <SEO title="Cruise Phase" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40" style={{borderBottom: "5px solid #D96C32"}}>
        <img src={imgCruiseCover} alt="" data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-100px", position: "relative"}}>
          <div className="large-container pb6">
            <div className="standard-container standard-bg">
                <div>
                  <h2 className="relative">
                    CRUISE PHASE
                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                  The Hope Probe is constantly monitored by the Operations Team back on Earth, as it makes its way to Mars. The trajectory to Mars is further refined by the team by performing a series of Trajectory Correction Maneuvers (TCM) over the next several months.  
                  </p>
                  <p>
                  During this period, the instruments are turned on and checked out to ensure that they are operating correctly. The instruments are calibrated using stars to ensure they are ready to operate once they arrive in Mars’ orbit.
                  </p>
                  <p>
                  The Hope Probe approaches Mars with such a velocity that it will slingshot around it and continue into deep space if it isn’t slowed down to the level that it can be captured by Mars’ gravity.
                  </p>
                </div>
            </div>    
          </div>  
      </section>
    </main>
  </Layout>
)

export default CruisePage
